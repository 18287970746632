import { message } from 'antd';
import axios from 'axios';
import { Button, notification, Space } from 'antd';

const axiosServices = axios.create();
//const baseUrl = `${process.env.REACT_APP_API_URL}/api/`;

const baseUrl = 'https://api.toranjserver.com/api/';
// Token helpers
export function getToken() {
    const jwt = localStorage.getItem('token');
    if (jwt) {
        return jwt;
    }
    return '';
}

export function getCurrentUsername() {
    return localStorage.getItem('user');
}

export function setToken(jwt) {
    localStorage.setItem('token', jwt);
}

export const getRefreshToken = () => {
    const jwt = JSON.parse(localStorage.getItem('token'));
    return jwt?.refreshToken;
}

// Interceptor for HTTP
axiosServices.interceptors.response.use(
    (response) => {
        return response;
    },
    async (err) => {
        const originalRequest = err.config;
        if (err?.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            message.error(err?.response.data.messages[0]);
            if (window.location.pathname !== '/auth/login') {
                window.location.href = '/auth/login'; 
            }
        } else {
            console.log("errrr", err?.response.data);
            originalRequest._retry = true;

            message.error(err?.response.data.messages[0] ?? 'internal error');
        }

        return Promise.reject((err.response && err.response.data) || 'Wrong Services');
    }
);
// axiosServices.interceptors.request.use((request)=>{
//     if(localStorage.getItem("token")!="") {
//         request.headers.set("authorizarion","bearer "+localStorage.getItem("token"));
//     }
// });

const publicHeader = {
    'content-type': 'application/json',
};

const privateHeader = {
    'content-type': 'application/json',
    'Authorization': `Bearer ${getToken()}`,
};

// Public POST request
export const publicPostRequest = (apiUrl, data) => {
    const url = `${baseUrl}${apiUrl}`;
    const headers = {
        'Content-Type': 'application/json',
        'accept': '*/*',
        'Accept-Language': 'fa-IR',
        "tenant":"root"
    };
    const config = {
        method:"POST",
        headers:headers,
        url:url,
        data: JSON.stringify(data) 
    };
    console.log(config);
    return axiosServices(config);
};

const ajaxLogin = (body) => {
    const myHeaders = new Headers();
    myHeaders.append("tenant", "root");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(body);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    let fetch = fetch("http://localhost:5001/api/tokens", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
    return fetch;
  };

// Private request
export const privateRequest = (method = 'post', apiUrl, data) => {
    const url = `${baseUrl}${apiUrl}`;

    const headers = {
        'Content-Type': 'application/json',
        'accept': '*/*',
        'Accept-Language': 'fa-IR',
        "tenant":"root",
        'Authorization': `Bearer ${getToken()}`,
    };
    const config = {
        method,
        headers,
        url,
        ...(method.toLowerCase() === 'get' ? { params: data } : { data: JSON.stringify(data) })
    };

    return axiosServices(config);
};

export default axiosServices;