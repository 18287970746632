import React from 'react';

import { useSelector } from 'react-redux';

import { Button, Badge, Row, Col, Dropdown, Divider, Avatar } from 'antd';
import { NotificationBing, TickCircle } from 'iconsax-react';

import avatarImg1 from '../../../assets/images/memoji/user-avatar-1.png';
import avatarImg2 from '../../../assets/images/memoji/user-avatar-2.png';
import avatarImg3 from '../../../assets/images/memoji/user-avatar-3.png';

export default function HeaderNotifications() {
  const direction = useSelector((state) => state.customise.direction);

  const notificationMenu = (
    <div
      className="hp-notification-dropdown hp-border-radius hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-pt-24 hp-pb-18 hp-px-24"
      style={{ marginTop: 23 }}
    >
     
   </div>
  );
}
