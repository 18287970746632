import React from 'react';

import { Card } from 'antd';

export default function FeatureCard(props) {
  return (
    <Card className="hp-border-radius-lx hp-dashboard-feature-card hp-cursor-pointer">
      {props.icon && (
        <div
          className="hp-d-flex-full-center hp-dashboard-feature-card-icon hp-border-radius-lg hp-bg-black-20 hp-bg-dark-80"
          style={{ width: 48, height: 48 }}
        >
          {props.icon}
        </div>
      )}

      <div>
        <div className="hp-d-flex">
          {props.title && (
            <span className=" hp-p3-body  hp-d-block hp-text-color-black-bg hp-text-color-dark-0 hp-font-weight-400 hp-mr-4">
              {props.title}
            </span>
          )}

          {props.titleIcon && props.titleIcon}
        </div>

        {props.date && (
          <span className="hp-caption hp-d-block hp-font-weight-400 hp-text-color-black-60">
            {props.date}
          </span>
        )}

        {props.price && (
          <span className="hp-d-block hp-mt-8 h3 hp-slashed-zero hp-font-weight-400">
            {props.price}
          </span>
        )}
      </div>
    </Card>
  );
}
