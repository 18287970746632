import { Link,useHistory } from 'react-router-dom';

import { Dropdown, Col, Divider, Row } from 'antd';
import { UserOctagon, Flag, Calendar, Calculator } from 'iconsax-react';

import avatarImg from '../../../assets/images/memoji/user-avatar-4.png';

export default function HeaderUser() {
  const history = useHistory();
  const menu = (
    <div className="hp-user-dropdown hp-border-radius hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-py-24 hp-px-18 hp-mt-16">
      <span className="hp-d-block h5 hp-font-weight-500 hp-text-color-black-100 hp-text-color-dark-0 hp-mb-16">
        ویرایش کاربر
      </span>

      <Link
        to="/user/profile"
        className="hp-p1-body hp-font-weight-500 hp-hover-text-color-primary-2"
      >
        مشاهده پروفایل
      </Link>

      <Divider className="hp-mt-18 hp-mb-12" />

      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Link
            to="/user/profile"
            className="hp-p1-body hp-font-weight-500 hp-hover-text-color-primary-2"
          >
            تنظیمات حساب
          </Link>
        </Col>

        <Col span={24}>
          <Link
            className="hp-p1-body hp-font-weight-500 hp-hover-text-color-primary-2"
            onClick={() => {
              localStorage.removeItem("token");
              history.push("/auth/login");
            }}
          >
            خروج
          </Link>
        </Col>
      </Row>
    </div>
  );

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <div className="hp-border-radius-full hp-cursor-pointer">
          <div
            className="hp-border-radius-full hp-overflow-hidden hp-bg-info-4 hp-m-4 hp-d-flex"
            style={{ minWidth: 44, width: 44, height: 44 }}
          >
            <img src={avatarImg} alt="User" height="100%" />
          </div>
        </div>
      </Dropdown>
    </Col>
  );
}
