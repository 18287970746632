import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { privateRequest, publicPostRequest } from "../../utils/axios";
import {message} from 'antd';
import { useHistory } from 'react-router-dom';


const initialState = {
  loginState: {
    status: '',
    data: {
      token: '',
      profileComplated: false,
      userData:{

      }
    },
  }
};

export const loginWithEmail = createAsyncThunk("/loginEmail", async (data) => {
    const response = await publicPostRequest(
        `tokens`,
        data);
      return response.data;
});
export const loginWithPhone = createAsyncThunk("/loginPhone", async (data) => {
    const response = await publicPostRequest(
        `tokens/tokenwithpassword`,
        data
    );
    return response.data;
});



export const loginReducerv2 = createSlice({
  name: "logiv2",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(loginWithEmail.pending, (state) => {
              state.loginState.status = "loading";
          })
          .addCase(loginWithEmail.fulfilled, (state, action) => {
              state.loginState.status = "loaded";
              state.loginState.data = action.payload;
          })
          .addCase(loginWithEmail.rejected, (state) => {
              state.loginState.status = "rejected";
           })

          .addCase(loginWithPhone.pending, (state) => {
              state.loginState.status = "loading";
          })
          .addCase(loginWithPhone.fulfilled, (state, action) => {
              state.loginState.status = "loaded";
              state.loginState.data = action.payload;
          })
          .addCase(loginWithPhone.rejected, (state) => {
              state.loginState.status = "rejected";
          })
      
  },
});

export default loginReducerv2.reducer;
