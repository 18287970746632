import { combineReducers } from "redux";

import calendarReducer from "./calendar/calendarReducer";
import contactReducer from "./contact/contactReducer";
import customiseReducer from "./customise/customiseReducer";
import ecommerceReducer from "./ecommerce/ecommerceReducer";
import loginReducerv2 from "./auth/loginReducer";
import registerV2 from "./auth/registerReducer";
import profile from "./profile/profileReducer";



const rootReducer = combineReducers({
  calendar: calendarReducer,
  contact: contactReducer,
  ecommerce: ecommerceReducer,
  customise: customiseReducer,
  loginv2: loginReducerv2,
  profile: profile,
  register: registerV2

});

export default rootReducer;