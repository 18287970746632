import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { privateRequest, publicPostRequest } from "../../utils/axios";
import { message } from 'antd';
import { useHistory } from 'react-router-dom';


const initialState = {
    profileState: {
        status: '',
        updateProfile: false,
        verifyEmailSent:false,
        data: {
            userData: {

            },
           token: '',
        },
        sendOtp: {
            status:''
        },
        verifyNational: {
            status:'' 
        },
        verifyPhone: {
            status:''
        }

    }
};

export const getProfile = createAsyncThunk("/getProfile", async () => {
    const response = await privateRequest("POST", 'users/get-user-data-from-token');
    return response.data.result;
});
export const updateProfile = createAsyncThunk("/completeProfile", async (data) => {
    const response = await privateRequest("POST", `users/complete-profile`, data);
    return response.data;
});

export const verifyEmail = createAsyncThunk(
    "verifyEmail",
    async (data) => {
        const response = await privateRequest("POST", `verification/sendEmail`, data);
        return response.data;
    }
)
export const sendVerificactionCellNumber = createAsyncThunk(
    "sendotp",
    async (data) => {
        const response = await privateRequest("POST", `verification/sendotp`, data);
        return response.data;
    }
);
export const verifyCellNumber = createAsyncThunk(
    "confirm-phone-number",async (data) => {
        const response = await privateRequest("POST", `users/confirm-phone-number`, data);
        return response.data;
    }
);
export const verifyNationalCode = createAsyncThunk(
    "verifynationalcodewithmobilenumber", async (data) => {
        const response = await privateRequest("POST", `verification/verifynationalcodewithmobilenumber`, data);
        return response.data;
    }

);

export const profileReducer = createSlice({
    name: "profileData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProfile.pending, (state) => {
                state.profileState.status = "loading";
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.profileState.status = "loaded";
                state.profileState.data = action.payload;
            })
            .addCase(getProfile.rejected, (state) => {
                state.profileState.status = "rejected";
            })

            .addCase(updateProfile.pending, (state) => {
                state.profileState.status = "loading";
                state.profileState.updateProfile = false;

            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.profileState.status = "loaded";
                state.profileState.updateProfile = true;
                state.profileState.data = action.payload;
            })
            .addCase(updateProfile.rejected, (state) => {
                state.profileState.status = "rejected";
                state.profileState.updateProfile = false;
            })




            .addCase(verifyEmail.pending, (state) => {
                state.profileState.status = "loading";
                state.profileState.verifyEmailSent = false;

            })
            .addCase(verifyEmail.fulfilled, (state, action) => {
                state.profileState.status = "loaded";
                state.profileState.data = action.payload;
                
                state.profileState.verifyEmailSent = true;

            })
            .addCase(verifyEmail.rejected, (state) => {
                state.profileState.status = "rejected";
                state.profileState.verifyEmailSent = false;

            })


            .addCase(sendVerificactionCellNumber.pending, (state) => {
                state.profileState.sendOtp.status = "loading";
            })
            .addCase(sendVerificactionCellNumber.fulfilled, (state, action) => {
                state.profileState.sendOtp.status = "loaded";
                state.profileState.sendOtp.data = action.payload;
            })
            .addCase(sendVerificactionCellNumber.rejected, (state) => {
                state.profileState.sendOtp.status = "rejected";
            })


            .addCase(verifyCellNumber.pending, (state) => {
                state.profileState.verifyPhone.status = "loading";
            })
            .addCase(verifyCellNumber.fulfilled, (state, action) => {
                state.profileState.verifyPhone.status = "loaded";
                state.profileState.verifyPhone.data = action.payload;
            })
            .addCase(verifyCellNumber.rejected, (state) => {
                state.profileState.verifyPhone.status = "rejected";
            })


            .addCase(verifyNationalCode.pending, (state) => {
                state.profileState.verifyNational.status = "loading";
            })
            .addCase(verifyNationalCode.fulfilled, (state, action) => {
                state.profileState.verifyNational.status = "loaded";
                state.profileState.verifyNational.data = action.payload;
            })
            .addCase(verifyNationalCode.rejected, (state) => {
                state.profileState.verifyNational.status = "rejected";
            })
        
    },
});

export default profileReducer.reducer;
