import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { privateRequest, publicPostRequest } from "../../utils/axios";
import {message} from 'antd';
import { useHistory } from 'react-router-dom';


const initialState = {
  registerState: {
    status: '',
    data: {
      token: {},
      userData:{

      }
    },
  }
};

export const register = createAsyncThunk("/register", async (data) => {
    const response = await publicPostRequest(
        `users/simple-register`,
        data);
      return response.data;
});


export const registerReducer = createSlice({
  name: "register",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(register.pending, (state) => {
              state.registerState.status = "loading";
          })
          .addCase(register.fulfilled, (state, action) => {
              state.registerState.status = "loaded";
              state.registerState.data = action.payload;
          })
          .addCase(register.rejected, (state) => {
              state.registerState.status = "rejected";
           })

      
  },
});

export default registerReducer.reducer;
