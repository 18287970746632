import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import {
  adjustItemQty,
  loadCurrentItem,
  removeFromCart,
} from '../../../redux/ecommerce/ecommerceActions';

import {
  Button,
  Row,
  Col,
  Divider,
  Tag,
  InputNumber,
  Empty,
  Avatar,
} from 'antd';
import { ShoppingBag } from 'iconsax-react';

import EmptyImage from '../../../assets/images/apps/ecommerce/checkout-empty.svg';

export default function HeaderCart() {
  // Basket Dropdwon
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.ecommerce.cart);
  const [totalItem, setTotalItem] = useState(0);

  useEffect(() => {
    let items = 0;

    cart.forEach((item) => {
      items += item.qty;
    });

    setTotalItem(items);
  }, [cart, totalItem, setTotalItem]);

  // Basket Qty
  const onChangeHandler = (e, id) => {
    dispatch(adjustItemQty(id, e));
  };

  // Remove Cart
  const [removeClass, setRemoveClass] = useState('');

  const handleClick = () => {
    setRemoveClass('remove-cart');

    setTimeout(() => {
      setRemoveClass('');
    }, 200);
  };


}
