import { Health, Setting, Grid5 } from 'iconsax-react';

import IntlMessages from '../../layout/components/lang/IntlMessages';

const main = [
  {
    header: <IntlMessages id="sidebar-dashboards" />,
  },
  {
    id: 'dashboards-analytics',
    title: <IntlMessages id="sidebar-dashboards-analytics" />,
    icon: <i className="ph-fill ph-chart-line" />,
    navLink: '/main/dashboard/analytics',
  },
  // {
  //   id: 'dashboards-ecommerce',
  //   title: <IntlMessages id="sidebar-dashboards-ecommerce" />,
  //   icon: <i className="ph-fill ph-shopping-cart-simple" />,
  //   navLink: '/main/dashboard/ecommerce',
  // },
];

export default main;
